<template>
  <v-row
      class="align-content-center"
  >
    <v-col
        cols="12"
        md="6"
    >
      <v-card
          variant="flat"
      >
        <v-card-title
            class="text-h3 text-md-h2 text-decoration-underline text-green-darken-4"
        >
          Our Mandate
        </v-card-title>
        <v-card-text
        >
          <v-list>
            <v-list-item
                v-for="(mandate, i) in mandates"
                :key="i"
            >
              <v-list-item-title class="text-h5">{{mandate.title}}</v-list-item-title>
              <v-list-item-subtitle class="text-h5 font-weight-light ">{{mandate.content}}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

    </v-col>
    <v-col
        cols="12"
        md="6"
        class="mx-auto my-auto"
    >
      <v-card
          :image="require('../assets/kids-river.jpg')"
          class="mx-auto"
          height="400"
          elevation="4"
          max-width="70%"
      >


      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MandateComponent",
  data () {
    return {
      mandates: [
        {
          title: 'Environmentally Sensitive Projects',
          content: 'The NGO implement projects that are sensitive to the environment'
        },
        {
          title: 'Target Marginalized ',
          content: 'The NGO implement projects that target people who socially, economically and culturally marginalised and live without dignity.'
        },
        {
          title: 'Culturally Sensitive Projects',
          content: 'The NGO implement projects that sensitive the culture and tradition of the beneficiaries'
        },
        {
          title: 'Areligious Projects',
          content: 'The NGO implement projects that do not seek to promote religious beliefs, norms and values.'
        },
        {
          title: 'Apolitical Projects',
          content: 'The NGO implement projects that are apolitical and do not seek to advance political gains. '
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>