<template>
<v-footer>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <span class="text-h6">Location</span>
        <v-list >
          <v-list-item class="font-weight-light" max-width="75%">
            Kalabash Aid Foundation<br/>
            Hansen Street/Cabinet Street
            House Number 37<br />
            Adenta, Accra
          </v-list-item>
        </v-list>

      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <span class="text-h6">Contacts</span>
        <v-list class="font-weight-light">
          <v-list-item class="">
            <v-icon icon="mdi-phone-in-talk"></v-icon>&nbsp;
            {{ phoneNumber }}
          </v-list-item>
          <v-list-item class="">
          <v-icon icon="mdi-mail"></v-icon>&nbsp;
          {{ email }}
        </v-list-item>
        </v-list>
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="4"
      >
        <span class="text-h6">Newsletter</span>
        <p class="mt-5 font-weight-light">Subscribe to our Newsletter</p>
        <v-form @submit.prevent="newsLetterSubmit">
          <v-text-field
              placeholder="Email Address"
              variant="outlined"
              density="compact"
              name="Email"
              type="email"
              v-model="userEmail"
          >
            <template v-slot:append>
              <v-btn color="amber text-white"  type="submit"><v-icon icon="mdi-send"></v-icon></v-btn>
            </template>
          </v-text-field>
        </v-form>
        <v-snackbar v-model="snackbar">
          {{ userEmail }} added to mailing list.

          <template v-slot:actions>
            <v-btn class="text-red-lighten-3" @click="unsubscribe">Unsubscribe</v-btn>

          </template>
        </v-snackbar>
        <v-snackbar v-model="removeBar">
          {{ userEmail }} removed from mailing list.

          <template v-slot:actions>
            <v-btn class="text-red-lighten-3" @click="removeBar = false">x</v-btn>

          </template>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-container>
      <v-list-item class="text-end">Developed by Jesse Zacharia</v-list-item>
    </v-container>

    <div class="d-flex justify-center">
     <v-icon icon="mdi-copyright" class="my-auto"></v-icon> &nbsp; {{ date }} - Kalabash-Aid
    </div>
  </v-container>
</v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      date: new Date().getFullYear(),
      phoneNumber: '+233 2432 868 06',
      email: 'kalabashaidgh@gmail.com',
      action: 'added to',
      userEmail: '',
      snackbar: false,
      removeBar: false
    }
  },
  methods: {
    newsLetterSubmit () {
      this.snackbar = true;
    },

    unsubscribe () {
      this.snackbar = false;
      this.removeBar = true;
    }
  }

}
</script>

<style scoped>

</style>