<template>
  <v-carousel
      show-arrows="hover"
      height="65vh"
      hide-delimiters
      cycle>
    <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :style="{transform: `translateY(${item.offset}px)`}"
        :src="item.image"
        :alt="item.altText"
        cover
    >

    </v-carousel-item>
  </v-carousel>

</template>

<script>
export default {
  name: "HomeSlider",
  data (){
    return {
      items: [
        {
          altText: 'Child washing hands at borehole',
          image: require('../assets/borehole-handwash.jpeg'),
          offset: 0
        },
        {
          altText:  'Kids fetching water from well',
          image: require('../assets/kids-well.jpeg'),
          offset: 0
        },
        {
          altText:  'kids operating a borehole',
          image: require('../assets/africankids-borehole.jpeg'),
          offset: 0
        },
        {
          altText:  'young girl at the borehole',
          image: require('../assets/girl-borehole.jpeg'),
          offset: 0
        },

      ]
    }
  }
}
</script>

<style scoped>
.v-img__img--cover {
  object-fit: fill;
}
</style>